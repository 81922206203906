var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            "overflow-auto": "",
            closable: true,
            visible: _vm.visible,
            title: _vm.modalTitle,
            "ok-text": "保存",
            size: "normal",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c("BlockTitle", {
                staticClass: "v-title",
                attrs: { title: "基本信息" },
              }),
              _c(
                "a-form-model-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "手机号", prop: "phoneNum" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.form.phoneNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phoneNum", $$v)
                      },
                      expression: "form.phoneNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "身份证", prop: "idNum" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入身份证" },
                    model: {
                      value: _vm.form.idNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "idNum", $$v)
                      },
                      expression: "form.idNum",
                    },
                  }),
                ],
                1
              ),
              _vm.isOldStaff
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: " ", prop: "idCard" } },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("该员工为离职状态，请选择入职方式"),
                      ]),
                      _c("a-radio-group", {
                        attrs: { options: _vm.typeOptions },
                        model: {
                          value: _vm.form.entryType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "entryType", $$v)
                          },
                          expression: "form.entryType",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "招聘方式", prop: "type" } },
                [
                  _c("dictionariesInput", {
                    attrs: { parameter: "recruit_type" },
                    model: {
                      value: _vm.form.recruitType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "recruitType", $$v)
                      },
                      expression: "form.recruitType",
                    },
                  }),
                ],
                1
              ),
              _c("BlockTitle", {
                staticClass: "v-title",
                attrs: { title: "入职信息" },
              }),
              _c(
                "a-form-model-item",
                { attrs: { label: "计划入职日期", prop: "planEntryDate" } },
                [
                  _c("a-date-picker", {
                    attrs: {
                      disabled: !_vm.isAdd,
                      "value-format": "YYYY-MM-DD",
                      placeholder: "请选择时间",
                    },
                    model: {
                      value: _vm.form.planEntryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "planEntryDate", $$v)
                      },
                      expression: "form.planEntryDate",
                    },
                  }),
                ],
                1
              ),
              !_vm.isAdd
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "实际入职日期", prop: "actualEntryDate" },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "disabled-date": _vm.disabledDateTime,
                          "value-format": "YYYY-MM-DD",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.form.actualEntryDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "actualEntryDate", $$v)
                          },
                          expression: "form.actualEntryDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isAdd
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "试用期", prop: "realEntryDate" } },
                    [
                      _c("a-select", {
                        attrs: {
                          placeholder: "请选择",
                          options: _vm.optionsType,
                        },
                        model: {
                          value: _vm.form.probationNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "probationNum", $$v)
                          },
                          expression: "form.probationNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "聘用形式", prop: "engageMode" } },
                [
                  _c("dictionariesInput", {
                    attrs: { parameter: "engage_mode" },
                    model: {
                      value: _vm.form.engageMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "engageMode", $$v)
                      },
                      expression: "form.engageMode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "部门", prop: "orgId" } },
                [
                  _c("depTreeSelect", {
                    attrs: { multiple: false, placeholder: "请选择部门" },
                    on: {
                      echoPost: _vm.echoPost,
                      getValueSelect: _vm.deepTreeChange,
                    },
                    model: {
                      value: _vm.form.orgId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orgId", $$v)
                      },
                      expression: "form.orgId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "岗位", prop: "post" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled: _vm.postDisabled,
                        "allow-clear": "",
                      },
                      on: { change: _vm.postListChange },
                      model: {
                        value: _vm.form.postId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "postId", $$v)
                        },
                        expression: "form.postId",
                      },
                    },
                    _vm._l(_vm.postList, function (item) {
                      return _c(
                        "a-select-opt-group",
                        {
                          key: item.postTypeId,
                          attrs: { label: item.postTypeName },
                        },
                        _vm._l(item.postList, function (it) {
                          return _c(
                            "a-select-option",
                            { key: it.postId, attrs: { value: it.postId } },
                            [_vm._v(" " + _vm._s(it.postName) + " ")]
                          )
                        }),
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "职务" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled: _vm.postDisabled,
                        "allow-clear": "",
                      },
                      model: {
                        value: _vm.form.duty,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "duty", $$v)
                        },
                        expression: "form.duty",
                      },
                    },
                    _vm._l(_vm.dutyList, function (it) {
                      return _c(
                        "a-select-option",
                        { key: it.dutyId, attrs: { value: it.dutyId } },
                        [_vm._v(" " + _vm._s(it.dutyName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "职级", prop: "level" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled: !_vm.form.postId,
                        "allow-clear": "",
                      },
                      model: {
                        value: _vm.form.jobGradeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jobGradeId", $$v)
                        },
                        expression: "form.jobGradeId",
                      },
                    },
                    _vm._l(_vm.rankList, function (item) {
                      return _c(
                        "a-select-opt-group",
                        {
                          key: item.jobTypeId,
                          attrs: { label: item.jobTypeName },
                        },
                        _vm._l(item.jobGradeList, function (it) {
                          return _c(
                            "a-select-option",
                            {
                              key: it.jobGradeId,
                              attrs: { value: it.jobGradeId },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(it.jobTypeCode) +
                                  _vm._s(it.jobGrade) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isAdd
            ? _c(
                "a-checkbox",
                {
                  staticClass: "checkbox",
                  model: {
                    value: _vm.form.entryRegistration,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "entryRegistration", $$v)
                    },
                    expression: "form.entryRegistration",
                  },
                },
                [_vm._v("发送入职登记表")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }